/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Dexter's Portfolio",
  description:
    "A tenacious and devoted achiever, I am absolutely committed into perfecting my ICT craft; an interest close to my heart without compromising. I can be counted on to weather the storms and produce the desired results.",
  og: {
    title: "Dexter Tng Portfolio",
    type: "website",
    url: "http://ashutoshhathidara.com/",
  },
};

//Home Page
const greeting = {
  title: "Dexter Tng",
  logo_name: "DexterTng",
  subTitle:
    "A tenacious and devoted achiever, I am absolutely committed into perfecting my ICT craft; an interest close to my heart without compromising. I can be counted on to weather the storms and produce the desired results.",
  resumeLink:
    "https://drive.google.com/drive/folders/1dzUavhE5M8Q1eP0ch0aY0Cv_5Vz8iAss?usp=sharing",
  portfolio_repository:
    "https://github.com/ChonkyQueen/Python_Basics_Practices",
  githubProfile: "https://github.com/ChonkyQueen",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/ChonkyQueen",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/dexter-tng-2b3974300/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCtNQ4WG9IaeC4pBIsGxu9bQ",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:dextertng2008@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://x.com/ChonkyyyQueen",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/dexter.tng/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/chonky__queen/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "My Inspiration",
      fileName: "DataScienceImg",
      skills: [
        "Upon receiving my first laptop in Primary 2, I fell in love with computers and anything tech-related. In order to pursue my passion and fuel my drive, I indulged in coding simple programs and games, either from my CCA in Primary School or by self-initiating my learning.",
      ],
      softwareSkills: [{}],
    },
    {
      title: "My Skills",
      fileName: "FullStackImg",
      skills: [
        "⚡ Basic understanding of Kali Linux and its many applications",
        "⚡ Basic understanding of Python",
        "⚡ Basic understanding of using Orange Data Mining",
        "⚡ Delved in basic Game Programming with Scratch 2.0 & Roblox Studio",
        "⚡ Experience with 3D Animation using CoSpaces",
        "⚡ Experienced in working with sound systems in school",
        "⚡ Experience with using Adobe Photoshop for Image Editing & Thumbnail Creation",
        "⚡ Experience with using Microsoft Powerpoint, mainly utilising Animations & Transitions",
      ],
      softwareSkills: [
        {
          skillName: "Kali Linux",
          fontAwesomeClassname: "devicon:kalilinux",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "logos-python",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Orange Data Mining",
          fontAwesomeClassname: "ph:orange-fill",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Scratch 2.0",
          fontAwesomeClassname: "simple-icons:scratch",
          style: {
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Roblox Studio",
          fontAwesomeClassname: "skill-icons:robloxstudio",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "CoSpaces",
          fontAwesomeClassname: "bi:badge-3d",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Audio Systems",
          fontAwesomeClassname: "marketeq:microphone-audio",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "devicon:photoshop",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Microsoft Powerpoint",
          fontAwesomeClassname: "vscode-icons:file-type-powerpoint",
          style: {
            color: "#663399",
          },
        },
      ],
    },
    {
      title: "My Personal Projects with Python",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ QR Code Generator that utilises the modules qrcode and image",
        "⚡ Text To Speech Generator that utilises the module gTTS",
        "⚡ Rock Paper Scissors against a computer",
        "⚡ Make sure to check them out on my github!",
      ],
      softwareSkills: [
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "",
          fontAwesomeClassname: "",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [{}],
};

const degrees = {
  degrees: [
    {
      title: "Anderson Primary School",
      subtitle: "Infocomm Club (CCA)",
      logo_path: "aps.png",
      alt_name: "Anderson Primary School",
      duration: "2015 - 2020",
      descriptions: [
        "⚡ Participated in many competitions related to ICT through my CCA.",
        "⚡ Learnt various IT-related skills thanks to compulsory school courses, as well as my CCA.",
        "⚡ Received the prestigious Raffles Scholarship in 2020, as well as the ECHA Award in 2018.",
        "⚡ Was the CCA Chairman in 2020.",
      ],
      website_link: "https://www.andersonpri.moe.edu.sg/",
    },
    {
      title: "Presbyterian High School",
      subtitle: "Audio Engineering Club (CCA)",
      logo_path: "phs.png",
      alt_name: "Presbyterian High School",
      duration: "2021 - 2024",
      descriptions: [
        "⚡ Learnt more about audio systems and equipment through my CCA.",
        "⚡ Apart from this, I have also participated in competitions related to ICT through my CCA.",
        "⚡ Became the CCA's Head Of Welfare in 2023.",
      ],
      website_link: "https://www.presbyterian.moe.edu.sg/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Academic Achievements",
      subtitle: "Primary + Secondary School",
      logo_path: "aps_phs.png",
      certificate_link:
        "https://drive.google.com/drive/folders/1gso_qgIfYikJUh4n8qGgT4YM3dOQbkB2?usp=drive_link",
      alt_name: "academicachievements",
      color_code: "#8C151599",
    },
    {
      title: "Character Achievements",
      subtitle: "Primary + Secondary School",
      logo_path: "aps_phs.png",
      certificate_link:
        "https://drive.google.com/drive/folders/1xPsNg0nP8ja1TuiO4Y8rWV65TTTXiuVN?usp=drive_link",
      alt_name: "characterachievements",
      color_code: "#00000099",
    },
    {
      title: "Other Achievements",
      subtitle: "Related to ICT",
      logo_path: "desktop.png",
      certificate_link:
        "https://drive.google.com/drive/folders/1Qqqdh-_mf77Lbbzo4a2jCMndYIe6F1kf?usp=sharing",
      alt_name: "otherachievements",
      color_code: "#0C9D5899",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Machine Learning Engineer",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "June 2023 - Present",
          location: "San Jose, CA, USA",
          description:
            "Improving ads ranking models on the core TikTok product. Experience working on modeling two-tower architectures like DeepFM, Wide & deep learning, etc. Working on Large Language Models (LLM) pretraining and Large Multi-modal Model (LMM) finetuning strategies.",
          color: "#000000",
        },
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - Aug 2021",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "San Francisco, USA",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects are based on what interested me at the time, and what I did to persue them",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Articles",
  description: "Some articles that really inspired me to persue my passion.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id:
        "local-employment-in-infocomm-media-sector-grew-5%-in-singapore-amid-covid-19",
      name:
        "Local employment in infocomm media sector grew 5% in Singapore amid Covid-19",
      createdAt: "2021-08-07",
      description: "Published on The Straits Times in 2021",
      url:
        "https://www.straitstimes.com/tech/tech-news/local-employment-in-infocomm-media-sector-grew-5-in-singapore-amid-covid-19",
    },
    {
      id: "techcrunch",
      name: "TechCrunch",
      createdAt: "2005-10-06",
      description: "Blog about technology and gadgets",
      url: "https://techcrunch.com/",
    },
    {
      id: "wired",
      name: "Wired",
      createdAt: "1993-02-01",
      description: "News aimed towards technology and its impact",
      url: "https://www.wired.com/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "circle_face.png",
    description: "Feel free to check out my socials, and view my portfolio in .pptx format via the See My Resume button to find out more about my ICT achievements!",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I would like to start a blog cataloging my journey in the ICT field soon.",
    link: "https://dev.to/chonkyqueen/first-post-53i0",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Block 643 Ang Mo Kio Avenue 5 #07-3013 560643",
    locality: "Singaporean",
    country: "Singapore",
    region: "Singapore",
    postalCode: "560643",
    streetAddress: "Ang Mo Kio Avenue 5",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/x6r4j6ga2HNFDKHv5",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "65+ 93487919",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
